import React from 'react'
import {Text} from 'wix-ui-tpa'
import {classes} from './item.st.css'
import {LegendItemProps} from './index'

export const LegendItem = ({color, label}: LegendItemProps) => (
  <li className={classes.legendItem}>
    <div
      className={classes.color}
      style={{backgroundColor: color, border: color === '#fff' ? '1px solid black' : undefined}}
    />
    <Text className={classes.text}>{label}</Text>
  </li>
)
