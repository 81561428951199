import React from 'react'
import ChevronUpSmall from 'wix-ui-icons-common/on-stage/ChevronUpSmall'
import ChevronDownSmall from 'wix-ui-icons-common/on-stage/ChevronDownSmall'
import classNames from 'classnames'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {TextButton, TextButtonPriority} from 'wix-ui-tpa'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {MODAL_BUTTONS} from '@wix/wix-events-commons-statics'
import {classes} from './legend.st.css'
import {LegendItem} from './item'
import {LegendProps} from './index'

export const Legend = ({legendItems, seatingMapButtonClick}: LegendProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState(false)

  const handleClick = () => {
    seatingMapButtonClick(expanded ? MODAL_BUTTONS.HIDE_PRICES : MODAL_BUTTONS.SHOW_PRICES)
    setExpanded(!expanded)
  }

  return (
    <div
      className={classNames(classes.container, {
        [classes.desktopContainer]: !isMobile,
      })}
      onClick={handleClick}
      data-hook={DH.PRICES_LEGEND}
    >
      <TextButton
        priority={TextButtonPriority.secondary}
        className={classes.label}
        suffixIcon={expanded ? <ChevronDownSmall /> : <ChevronUpSmall />}
        tabIndex={-1}
      >
        {expanded ? t('seatings_legend_title_expanded') : t('seatings_legend_title')}
      </TextButton>
      <ul aria-hidden={!expanded} className={classNames(classes.list, {[classes.listExpanded]: expanded})}>
        {legendItems.map(({color, price}, index) => (
          <LegendItem key={index} color={color} label={price} />
        ))}
      </ul>
    </div>
  )
}
