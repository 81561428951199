import {withTranslation} from '@wix/yoshi-flow-editor'
import {getEventTicketLimitPerOrder} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {isChooseSeatMode} from '../../../selectors/navigation'
import {
  getPlaces,
  getSelectedPlace,
  getSelectedTicketsWithPlaceInfo,
  isTicketLimitReached,
} from '../../../selectors/seating'
import {getReservationError} from '../../../selectors/reservation'
import {getSelectedTicketsQuantity} from '../../../selectors/selected-tickets'
import {SeatingPlanRuntimeProps} from './interfaces'
import {SeatingPlan as Presentation} from './seating-plan'

const mapRuntime = ({
  state: {
    seating: {loading, showAccessibilityMode, error},
  },
  state,
  actions: {
    checkout,
    setShowAccessibilityMode,
    addPlaceToBasket,
    selectPlace,
    unselectPlace,
    setSeatingError,
    setPlaceDonationError,
  },
}: DetailsPageAppProps): SeatingPlanRuntimeProps => ({
  addPlaceToBasket,
  setPlaceDonationError,
  checkout,
  loading,
  seatingError: error,
  reservationError: getReservationError(state),
  ticketLimitReached: isTicketLimitReached(state),
  placesInBasket: getSelectedTicketsWithPlaceInfo(state),
  showAccessibilityMode,
  chooseSeatMode: isChooseSeatMode(state),
  setShowAccessibilityMode,
  selectedPlace: getSelectedPlace(state),
  unselectPlace,
  selectPlace,
  setSeatingError,
  placesWithTicketInfo: getPlaces(state),
  selectedTicketsQuantity: getSelectedTicketsQuantity(state),
  eventTicketLimitPerOrder: getEventTicketLimitPerOrder(state.event),
})

export const SeatingPlanModal = connect<{}, SeatingPlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
