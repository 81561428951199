import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {InvoiceBreakdown} from '../../invoice-breakdown'
import {SummaryDivider} from '../summary-divider'
import SummaryStyles from '../summary.scss'
import {OrderedTicket} from '../../../types'
import c from '../../classnames.scss'
import {Ticket} from './ticket'
import {Place} from './place'
import {TicketsSummaryProps} from './index'

export const TicketsSummary = ({currency, tickets, seatingPlan}: TicketsSummaryProps) => {
  const hasSeats = tickets.some(ticket => ticket.seats?.placeIds.length)

  if (!tickets.length) {
    return null
  }

  return (
    <div className={c.formSummaryBody} data-hook={DH.TICKETS_SUMMARY}>
      <div className={SummaryStyles.summaryTicketsContainer}>
        <SummaryDivider />
        {hasSeats
          ? tickets.map(ticket =>
              ticket.seats.placeIds.map((seatId, seatIndex) => (
                <>
                  <Place
                    key={seatId}
                    seatId={seatId}
                    priceOverride={ticket.seats.donations?.[seatIndex]}
                    currency={currency}
                    seatingPlan={seatingPlan}
                    ticket={tickets.filter(ticket => ticket.seats.placeIds.includes(seatId))[0]}
                  />
                  <SummaryDivider />
                </>
              )),
            )
          : tickets.map((ticket: OrderedTicket) => <Ticket key={ticket.id} ticket={ticket} currency={currency} />)}
      </div>
      <InvoiceBreakdown />
    </div>
  )
}
