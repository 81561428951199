import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {isChooseSeatMode} from '../../../../../selectors/navigation'
import {getSelectedTicketsWithPlaceInfo, getTotalSelectedTicketsQuantity} from '../../../../../selectors/seating'
import {SidebarOwnProps, SidebarRuntimeProps} from './interfaces'
import {Sidebar as Presentation} from './sidebar'

const mapRuntime = ({state}: DetailsPageAppProps): SidebarRuntimeProps => ({
  showAccessibilityMode: state.seating.showAccessibilityMode,
  chooseSeatMode: isChooseSeatMode(state),
  placesInBasketCount: getTotalSelectedTicketsQuantity(state),
  placesInBasket: getSelectedTicketsWithPlaceInfo(state),
})

export const Sidebar = connect<SidebarOwnProps, SidebarRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
