import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import classNames from 'classnames'
import React, {useCallback, useEffect} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../classnames.scss'
import {RegFormData} from '../../../../types'
import {FormStep} from '../../../../constants'
import {usePrevious} from '../../../../../../../commons/hooks/use-previous'
import {TicketDetailsProps} from './interfaces'
import s from './ticket-details.scss'
import {TicketDetailsHeader} from './ticket-details-header'
import {TicketDetailsForm} from './ticket-details-form'

export const TicketDetails = ({
  details,
  editable,
  expanded,
  index,
  onExpand,
  ticket,
  formId,
  setTicketDetails,
  onSubmit,
  setUseBuyerDetails,
  useBuyerDetails,
}: TicketDetailsProps) => {
  const previousExpanded = usePrevious(expanded)

  useEffect(() => {
    if (expanded && previousExpanded !== expanded && previousExpanded !== undefined) {
      setTimeout(() => {
        focusElement({selector: hookToAttributeSelector(DH.TICKET_DETAILS_FORM(index))})
      }, 10)
    }
  }, [expanded])

  useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.CHECKOUT_STEP(FormStep.TicketsDetails))})
  }, [])

  const submitTicketDetails = useCallback(
    (data: RegFormData) => {
      setTicketDetails(index, data, true)
      onSubmit()
    },
    [index],
  )

  const toggleUseBuyerDetails = useCallback(
    () => setUseBuyerDetails({ticketIndex: index, useBuyerDetails: !useBuyerDetails}),
    [index, useBuyerDetails],
  )

  return (
    <div
      className={classNames(c.formLabelsFont, c.formTitleColor, {
        [s.editable]: editable,
        [s.expanded]: expanded,
        [s.filled]: details,
      })}
      data-hook={DH.TICKET_DETAILS_FORM(index)}
    >
      <TicketDetailsHeader
        expanded={expanded}
        onExpand={onExpand}
        details={details}
        ticketIndex={index}
        ticket={ticket}
        editable={editable}
      />
      <TicketDetailsForm
        formId={formId}
        ticketIndex={index}
        expanded={expanded}
        toggleUseBuyerDetails={toggleUseBuyerDetails}
        submitTicketDetails={submitTicketDetails}
      />
      <div className={s.divider} />
    </div>
  )
}
