import React, {useCallback, useEffect} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {DonationInput} from '../../../../donation-input'
import {DONATION_ERROR} from '../../../../../types'
import {usePrevious} from '../../../../../../../../commons/hooks/use-previous'
import {checkDonationError} from '../../../../../services/donations'
import {classes} from './ticket-donation.st.css'
import {TicketDonationProps} from './interfaces'

export const TicketDonation = ({
  place,
  error,
  addPlaceDonation,
  setPlaceDonationError,
  showAccessibilityMode,
  chooseSeatMode,
}: TicketDonationProps) => {
  const {t} = useTranslation()
  const {ticket, donation} = place
  const previousShowAccessibilityMode = usePrevious(showAccessibilityMode)
  const wholeTable = place.reservationOptions?.reserveWholeElement

  useEffect(() => {
    if (previousShowAccessibilityMode !== showAccessibilityMode) {
      if (error === DONATION_ERROR.EMPTY_DONATION) {
        setPlaceDonationError({placeId: place.id, error: undefined})
      }
    }
  }, [showAccessibilityMode, previousShowAccessibilityMode, error])

  const onChange = useCallback(
    newDonation => {
      if (error) {
        setPlaceDonationError({placeId: place.id, error: undefined})
      }
      addPlaceDonation({placeId: place.id, donation: newDonation})
    },
    [error, setPlaceDonationError, addPlaceDonation, place.id],
  )

  const onBlur = useCallback(() => {
    const error = checkDonationError(ticket, donation, {
      checkOnlyErrors: chooseSeatMode ? undefined : [DONATION_ERROR.MINIMUM_NOT_REACHED],
    })
    if (error) {
      setPlaceDonationError({placeId: place.id, error})
    }
  }, [ticket, donation, setPlaceDonationError, place.id])

  return (
    <DonationInput
      key={`${place.id} ${showAccessibilityMode}`} // remount on mode change to sync value
      label={wholeTable ? t('tickets.donationInputLabel.wholeTable') : undefined}
      ticket={ticket}
      onChange={onChange}
      onBlur={onBlur}
      value={donation}
      error={error}
      className={classes.root}
    />
  )
}
