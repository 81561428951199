import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../../../../../commons/components/runtime-context/connect'
import {WholeElementReservation as Presentation} from './whole-element-reservation'
import {WholeElementReservationRuntimeProps, WholeElementReservationOwnProps} from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): WholeElementReservationRuntimeProps => ({
  event: state.event,
})

export const WholeElementReservation = connect<WholeElementReservationOwnProps, WholeElementReservationRuntimeProps>(
  mapRuntime,
)(Presentation)
export * from './interfaces'
