import {PlaceWithTicketInfo} from '@wix/wix-events-commons-statics'
import {TFunction, useTranslation} from '@wix/yoshi-flow-editor'
import {Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import React from 'react'
import ChevronDown from 'wix-ui-icons-common/ChevronDown'
import ChevronUp from 'wix-ui-icons-common/ChevronUp'
import s from '../ticket-details.scss'
import {TicketDetailsHeaderProps} from './interfaces'

export const TicketDetailsHeader = ({
  ticketIndex,
  ticket,
  editable,
  expanded,
  onExpand,
  details,
}: TicketDetailsHeaderProps) => {
  const {t} = useTranslation()
  const place: PlaceWithTicketInfo = ticket.place

  return (
    <div className={s.header} onClick={editable ? onExpand : null}>
      <div className={s.title}>
        <div>
          {t('mobile.checkout.ticket.title', {
            index: ticketIndex + 1,
            name: ticket.name,
            interpolation: {escapeValue: false},
          })}
        </div>
        {editable && (expanded ? <ChevronUp className={s.arrow} /> : <ChevronDown className={s.arrow} />)}
      </div>
      {place && <div className={s.placeSubtitle}>{getPlaceSubtitle(place, t)}</div>}
      {details && !expanded && (
        <div className={s.details}>
          <div>
            {details.firstName} {details.lastName}
          </div>
          <div>{details.email}</div>
        </div>
      )}
    </div>
  )
}

const getPlaceSubtitle = (place: PlaceWithTicketInfo, t: TFunction) => {
  const area = place.elementType === Type.AREA
  const table = [Type.TABLE, Type.ROUND_TABLE].includes(place.elementType)

  const sector = place.sector ? `${t('seatings_sector')}: ${place.sector}` : ''
  const elementType = area
    ? `${t('seatings_area')}: ${place.elementLabel}`
    : table
    ? `${t('seatings_table')}: ${place.elementLabel}`
    : `${t('seatings_row')}: ${place.elementLabel}`
  const seat = !area ? `${t('seatings_seat')}: ${place.label}` : ''
  return [sector, elementType, seat].filter(val => val).join(', ')
}
