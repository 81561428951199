import React from 'react'
import {Text} from 'wix-ui-tpa'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {classes} from './tickets-count-info.st.css'
import {TicketsCountInfoProps} from './index'

export const TicketsCountInfo = ({placesCount}: TicketsCountInfoProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  return (
    <Text
      data-hook={DH.TICKETS_COUNT}
      tagName="h2"
      className={classNames(classes.seatsInfo, {[classes.mobile]: isMobile})}
    >
      {placesCount === 1 ? t('seatings_ticket_total') : t('seatings_tickets_total', {count: placesCount})}
    </Text>
  )
}
