import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {getTotalSelectedTicketsQuantity} from '../../../../../selectors/seating'
import {getInvoice} from '../../../../../selectors/placed-order'
import {FooterRuntimeProps, FooterOwnProps} from './interfaces'
import {Footer as Presentation} from './footer'

const mapRuntime = ({state, actions: {setShowAccessibilityMode}}: DetailsPageAppProps): FooterRuntimeProps => ({
  selectedTicketsQuantity: getTotalSelectedTicketsQuantity(state),
  invoice: getInvoice(state),
  showAccessibilityMode: state.seating.showAccessibilityMode,
  setShowAccessibilityMode,
})

export const Footer = connect<FooterOwnProps, FooterRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
