import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {SummaryContainer} from '../../summary-container'
import {InnerSummary} from '../../inner-summary'
import {ButtonStyle} from '../../button-style'
import {FooterProps} from './index'

export const Footer = ({
  selectedTicketsQuantity,
  invoice,
  showAccessibilityMode,
  setShowAccessibilityMode,
  onButtonClick,
}: FooterProps) => {
  const {t} = useTranslation()
  const handleButtonClick = () => (onButtonClick ? onButtonClick() : setShowAccessibilityMode(false))

  return (
    <SummaryContainer inAccessibilityMode={showAccessibilityMode}>
      <InnerSummary invoice={invoice} selectedTicketsQuantity={selectedTicketsQuantity} withoutBreakdown />
      <ButtonStyle onClick={handleButtonClick} text={t('seatings_viewBasket')} dataHook={DH.VIEW_BASKET} />
    </SummaryContainer>
  )
}
