import React from 'react'
import {Tickets} from '../../tickets'
import {AccessibilityMode} from '../../accessibility-mode'
import {TicketDescription} from '../../ticket-description'
import s from './sidebar.scss'
import {SidebarProps} from './index'

export const Sidebar = ({
  showAccessibilityMode,
  onRemoveClick,
  onCheckoutClick,
  chooseSeatMode,
  placesInBasket,
  placesInBasketCount,
  setForcedPopover,
  selectedSeatForDescription,
  showSeatDescription,
  selectSeatForDescription,
  setAccessibilityToast,
}: SidebarProps) => (
  <div className={s.sidebar}>
    <Tickets
      placesInBasketCount={placesInBasketCount}
      placesInBasket={placesInBasket}
      show={!showAccessibilityMode && !showSeatDescription}
      onRemoveClick={onRemoveClick}
      onCheckoutClick={onCheckoutClick}
      onTicketMoreInfoClick={selectSeatForDescription}
      chooseSeatMode={chooseSeatMode}
      setForcedPopover={setForcedPopover}
      selectedSeatForDescription={selectedSeatForDescription}
    />
    <AccessibilityMode setForcedPopover={setForcedPopover} setAccessibilityToast={setAccessibilityToast} />
    {!showAccessibilityMode ? (
      <TicketDescription
        selectedSeatForDescription={selectedSeatForDescription}
        show={showSeatDescription}
        onClose={() => selectSeatForDescription(null)}
      />
    ) : null}
  </div>
)
