import {getEventTicketLimitPerOrder} from '@wix/wix-events-commons-statics'
import {getFilteredPlaces, isTicketLimitReached} from '../../../../../selectors/seating'
import {getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {TicketsListOwnProps, TicketsListRuntimeProps} from './interfaces'
import {TicketsList as Presentation} from './tickets-list'

const mapRuntime = ({
  state,
  actions: {addPlaceToBasket, setSeatingError, resetFilters, setPlaceDonationError},
}: DetailsPageAppProps): TicketsListRuntimeProps => ({
  addPlaceToBasket,
  setSeatingError,
  setPlaceDonationError,
  ticketLimitReached: isTicketLimitReached(state),
  places: getFilteredPlaces(state),
  resetFilters,
  selectedTicketsQuantity: getSelectedTicketsQuantity(state),
  eventTicketLimitPerOrder: getEventTicketLimitPerOrder(state.event),
})

export const TicketsList = connect<TicketsListOwnProps, TicketsListRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
