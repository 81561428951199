import {isAssignedTickets, isTicketed} from '@wix/wix-events-commons-statics'
import {connect} from '../runtime-context/context'
import {getDefaultFormValues} from '../../selectors/current-member-details'
import {DISABLED_PREFILLED_FORM_FIELDS} from '../form/constants'
import {DetailsPageAppProps} from '../app/interfaces'
import {BuyerDetails as Presentation} from './buyer-details'
import {BuyerDetailsOwnProps, BuyerDetailsRuntimeProps} from './interfaces'
import {getInitialValues} from './utils'

const mapRuntime = ({state}: DetailsPageAppProps): BuyerDetailsRuntimeProps => {
  const {placedOrder, event, checkout, rsvp} = state
  const ticketed = isTicketed(event)
  const initialTicketedValues = placedOrder?.order ? getInitialValues(placedOrder.order) : checkout.buyerDetails ?? {}
  const filledValues = ticketed ? initialTicketedValues : rsvp.rsvpDetails ?? {}
  const initialValues = Object.keys(filledValues).length ? filledValues : getDefaultFormValues(state)

  return {
    details: ticketed ? checkout.buyerDetails : rsvp.rsvpDetails,
    initialValues,
    disabledFields: initialValues.email ? DISABLED_PREFILLED_FORM_FIELDS : [],
    assignedTicketsEnabled: ticketed && isAssignedTickets(event),
  }
}

export const BuyerDetails = connect<BuyerDetailsOwnProps, BuyerDetailsRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
