import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {SuccessActions} from '../common/success-actions'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {GroupCard} from '../../group-card'
import {Desktop} from './desktop'
import {Mobile} from './mobile'
import s from './order-success.scss'
import {OrderSuccessProps} from './index'

export const OrderSuccess: React.FC<OrderSuccessProps> = props => {
  const {t, eventTitle, dateTbd, template, orderPending, isAssignedTickets, order, showGroup} = props

  const registrationCompleteMsg = isAssignedTickets
    ? 'ticketsThankYou.regCompleteWithAssignedTickets'
    : 'ticketsThankYou.regComplete'

  let ticketsSentMsg = 'ticketsThankYou.ticketSent'
  if (orderPending) {
    if (order.ticketsQuantity === 1) {
      ticketsSentMsg = 'ticketsThankYou.regProcessing.singleTicket'
    } else {
      ticketsSentMsg = 'ticketsThankYou.regProcessing.multipleTickets'
    }
  } else if (isAssignedTickets) {
    ticketsSentMsg = 'ticketsThankYou.ticketSentWithAssignedTickets'
  }

  const downloadTicketsVisible = !template && !orderPending
  const addToCalendarVisible = !dateTbd

  React.useEffect(() => {
    if (!template && order) {
      props.ticketedThankYouPageLoaded()
    }

    focusElement({selector: hookToAttributeSelector(DH.ORDER_SUCCESS_TITLE)})
  }, [])

  if (!order) {
    return null
  }

  const {firstName, email} = order

  return (
    <Container dataHook={DH.ORDER_SUCCESS}>
      <div className={s.header}>
        <Title className={s.title} text={t('ticketsThankYou.thanks', {firstName})} dataHook={DH.ORDER_SUCCESS_TITLE} />
        <Subtitle
          lines={[
            t(registrationCompleteMsg, {eventTitle, interpolation: {escapeValue: false}}),
            t(ticketsSentMsg, {email}),
          ]}
          dataHook={DH.ORDER_SUCCESS_SUBTITLE}
          noBottomMargin={!addToCalendarVisible && !downloadTicketsVisible}
        />
        <SuccessActions
          t={t}
          className={s.successActions}
          addToCalendarVisible={addToCalendarVisible}
          downloadTicketsVisible={downloadTicketsVisible}
        />
        {showGroup ? <GroupCard /> : null}
      </div>
      <div className={s.desktop} data-hook={DH.DESKTOP}>
        <Desktop {...props} />
      </div>
      <div className={s.mobile} data-hook={DH.MOBILE}>
        <Mobile {...props} />
      </div>
    </Container>
  )
}
