import React from 'react'
import {IconButton} from 'wix-ui-tpa'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import SelectFill from 'wix-ui-icons-common/on-stage/SelectFill'
import classNames from 'classnames'
import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {useColors} from '../../../../../hooks/colors'
import {getPlaceInfoText} from '../../../../../selectors/seating'
import {WholeElementReservation} from '../whole-element-reservation'
import {classes} from './ticket-info.st.css'
import {TicketInfoTexts} from './ticket-info-texts'
import {CustomDatesLabel} from './custom-dates-label'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  place,
  showAccessibilityMode,
  onAddClick,
  onCloseClick,
  onRemoveClick,
  inPopover,
  basketView,
  inDescription,
}: TicketInfoProps) => {
  const {t} = useTranslation()
  const {darkColor} = useColors()
  const showCustomDatesLabel = onAddClick || showAccessibilityMode || inPopover
  const {inBasket, selected} = place
  const isDonation = place.ticket ? isDonationTicketDefinition(place.ticket) : false
  const showCheckbox = inBasket && !selected && !basketView && !inPopover && !inDescription

  return (
    <div className={classes.container}>
      {showCheckbox ? (
        <SelectFill
          className={classNames(classes.checkbox, {
            [classes.checkboxFillWired]: darkColor,
            [classes.checkboxFillNotWired]: !darkColor,
          })}
        />
      ) : null}
      {onCloseClick ? (
        <IconButton className={classes.headerButton} icon={<CloseSmall />} onClick={onCloseClick} />
      ) : null}
      {onRemoveClick ? (
        <IconButton
          data-hook={DH.REMOVE_TICKET}
          className={classes.headerButton}
          icon={<DeleteSmall />}
          onClick={onRemoveClick}
          aria-label={`${t('seatings_removeFromBasket')} ${getPlaceInfoText(place, t)}`}
        />
      ) : null}
      {showCustomDatesLabel && <CustomDatesLabel place={place} />}
      <TicketInfoTexts place={place} inPopover={inPopover} inDescription={inDescription} />
      {(inPopover || onAddClick) && !isDonation ? (
        <WholeElementReservation place={place} basketView={false} inDescription={false} showAccessibilityMode={false} />
      ) : null}
    </div>
  )
}
