import classNames from 'classnames'
import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getFormClassName} from '../../selectors/form'
import c from '../classnames.scss'
import {FormButton} from '../form-button'
import {RegistrationLayout} from '../registration-layout'
import {Summary} from '../summary'
import {Step, FormSteps} from '../form-steps'
import {RsvpFormProps} from './interfaces'
import s from './rsvp-form.scss'
import {RsvpFormContainer} from './container'

export const RsvpForm = ({
  closedRegistrationMessage,
  registrationClosed,
  buttonStyle,
  memberRsvpReady,
  memberRsvpExists,
  ticketedEvent,
  shouldNavigateToForm,
  navigateToMainPage,
  formPageLoaded,
}: RsvpFormProps) => {
  const {t} = useTranslation()

  React.useEffect(() => {
    formPageLoaded()
  }, [])

  if (!memberRsvpReady || (memberRsvpExists && !ticketedEvent && !shouldNavigateToForm)) {
    return null
  }

  const containerClass = classNames(c.formBackgroundColor, getFormClassName(buttonStyle))

  if (registrationClosed) {
    return (
      <div className={classNames(containerClass, s.closedRegistrationMessageContainer)}>
        <div className={classNames(s.closedRegistrationMessage, c.formTitleFont, c.formTitleColor)}>
          {closedRegistrationMessage.message}
        </div>
        <FormButton
          text={closedRegistrationMessage.exploreEventsActionLabel}
          width="auto"
          onClick={() => navigateToMainPage()}
        />
      </div>
    )
  }

  return (
    <RsvpFormContainer>
      {(steps: Step[], currentStep: string) => (
        <div className={containerClass}>
          <RegistrationLayout Right={<Summary />} Left={<FormSteps currentStep={currentStep} steps={steps} t={t} />} />
        </div>
      )}
    </RsvpFormContainer>
  )
}
