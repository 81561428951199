import React, {useCallback, useEffect} from 'react'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {TextButton} from 'wix-ui-tpa'
import ChevronRightSmall from 'wix-ui-icons-common/on-stage/ChevronRightSmall'
import ChevronLeftSmall from 'wix-ui-icons-common/on-stage/ChevronLeftSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import classNames from 'classnames'
import {BI_ORIGINS} from '@wix/wix-events-commons-statics'
import {useColors} from '../../../../../hooks/colors'
import {ButtonStyle} from '../../button-style'
import {getPlaceInfoText, isPlaceArea} from '../../../../../selectors/seating'
import {TicketAreaCounter} from '../ticket-area-counter'
import {classes} from './ticket-action.st.css'
import {TicketActionProps} from './index'

export const TicketAction = ({
  showAccessibilityMode,
  inDescription,
  onClick,
  place,
  selectedTicketQuantity,
  chooseSeatMode,
  onAddClick,
  scheduled,
  addPlaceToBasket,
  description,
  basketView = false,
}: TicketActionProps) => {
  const {isRTL, isMobile} = useEnvironment()
  const {darkColor} = useColors()
  const {t} = useTranslation()
  const placeInfoText = getPlaceInfoText(place, t)
  const area = isPlaceArea(place)

  const [amount, setAmount] = React.useState(area ? selectedTicketQuantity : 1)

  useEffect(() => {
    setAmount(showAccessibilityMode || selectedTicketQuantity ? selectedTicketQuantity : 1)
  }, [selectedTicketQuantity])

  const handleCounterChange = useCallback(
    (newValue: number) => {
      if (showAccessibilityMode || chooseSeatMode || !isMobile || basketView) {
        addPlaceToBasket({
          placeId: place.id,
          count: newValue,
          origin: showAccessibilityMode ? BI_ORIGINS.SEATINGS_MODAL_TICKET_LIST : BI_ORIGINS.SEATINGS_MODAL_BASKET,
        })
      }
      setAmount(newValue)
    },
    [chooseSeatMode, showAccessibilityMode, addPlaceToBasket, place.id, isMobile, basketView],
  )

  if (scheduled || inDescription) {
    return null
  }

  if (showAccessibilityMode && (!area || chooseSeatMode)) {
    const buttonText = place.inBasket ? t('seatings_removeFromBasket') : t('seatings_addToBasket')

    return (
      <TextButton
        data-hook={DH.ADD}
        className={classNames({
          [classes.addButton]: !place.inBasket,
          [classes.removeButton]: place.inBasket,
          [classes.buttonNotWired]: !darkColor,
        })}
        prefixIcon={place.inBasket ? <DeleteSmall /> : undefined}
        aria-label={`${buttonText} ${placeInfoText}`}
      >
        {buttonText}
      </TextButton>
    )
  }

  return (
    <>
      {area && !chooseSeatMode ? (
        <TicketAreaCounter
          value={amount}
          setValue={handleCounterChange}
          place={place}
          showAccessibilityMode={showAccessibilityMode}
        />
      ) : null}
      {basketView && description ? (
        <TextButton
          data-hook={DH.MORE_BUTTON}
          className={classNames(classes.moreInfo, {
            [classes.buttonNotWired]: !darkColor,
            [classes.moreInfoWithCounter]: area,
          })}
          suffixIcon={isRTL ? <ChevronLeftSmall /> : <ChevronRightSmall />}
          onClick={onClick}
          aria-label={`${t('seatings_ticket_moreInfo')} ${placeInfoText}`}
        >
          {t('seatings_ticket_moreInfo')}
        </TextButton>
      ) : null}
      {!basketView && place.selected ? (
        <ButtonStyle
          key="add-to-basket-button"
          className={classes.buttonNoMargin}
          dataHook={DH.ADD}
          text={t('seatings_add')}
          onClick={e => {
            onAddClick(amount)
            e.currentTarget.blur()
          }}
        />
      ) : null}
    </>
  )
}
