import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Divider, Text} from 'wix-ui-tpa'
import {getPreliminaryInvoice, getTaxConfig, isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {getTicketPriceText} from '../../../../../selectors/order-success'
import {classes} from './whole-element-reservation.st.css'
import {WholeElementReservationProps} from '.'

export const WholeElementReservation = ({
  place: {places, reservationOptions, ticket, donation},
  basketView,
  inDescription,
  showAccessibilityMode,
  event,
}: WholeElementReservationProps) => {
  const {t} = useTranslation()

  if (!reservationOptions?.reserveWholeElement) {
    return null
  }

  const getTotalPrice = () => {
    const isDonation = isDonationTicketDefinition(ticket)

    if (isDonation && !donation) {
      return ''
    }

    const priceOverrides = isDonation ? new Array(places?.length ?? 0).fill(donation ?? '0') : undefined

    return getTicketPriceText(
      getPreliminaryInvoice([ticket], {[ticket.id]: {quantity: places.length, priceOverrides}}, getTaxConfig(event))
        .grandTotal,
      t,
    )
  }

  return (
    <div className={basketView || inDescription || showAccessibilityMode ? classes.container : ''}>
      <Divider className={classes.divider} />
      <Text className={classes.tableReservation}>
        {t('seating_tableReservation', {
          price: getTotalPrice(),
        })}
      </Text>
    </div>
  )
}
