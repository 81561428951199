import React from 'react'
import {Text} from 'wix-ui-tpa'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {LabelWithValue} from '../../../../../common/label-with-value'
import {getPlaceInfoItems} from '../../../../../../selectors/seating'
import {TicketPrice} from '../../ticket-price'
import {classes} from './ticket-info-texts.st.css'
import {TicketInfoTextsProps} from './index'

export const TicketInfoTexts = ({place, inPopover, inDescription}: TicketInfoTextsProps) => {
  const {t} = useTranslation()
  const {
    placeInfo,
    ticketInfo: {ticketName, fees, feesInfo},
  } = getPlaceInfoItems(place, t)

  return (
    <Text tagName="p" className={classes.container}>
      <Text tagName="p" className={classNames(classes.infoContainer, {[classes.paddingRight]: !inPopover})}>
        {placeInfo.map(({label, value}, index) => (
          <LabelWithValue key={index} labelClassName={classes.label} label={label} value={value} />
        ))}
      </Text>
      <Text tagName="p" className={classes.ticketInfo}>
        <Text className={classes.ticketName}>{ticketName}</Text>
        <TicketPrice place={place} readonly={inPopover || inDescription} inDescription={inDescription} />
        <Text className={classes.smallText}>
          {inPopover && place.ticket && isDonationTicketDefinition(place.ticket) ? feesInfo : fees}
        </Text>
      </Text>
    </Text>
  )
}
