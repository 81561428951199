import React from 'react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useColors} from '../../../../hooks/colors'
import {isPlaceArea} from '../../../../selectors/seating'
import {TicketInfo} from './ticket-info'
import {classes} from './ticket.st.css'
import {TicketAction} from './ticket-action'
import {WholeElementReservation} from './whole-element-reservation'
import {TicketProps} from '.'

export const Ticket = ({
  place,
  inDescription,
  showAccessibilityMode,
  onRemoveClick,
  onClick,
  onTicketMoreInfoClick,
  setForcedPopover,
  basketView = false,
  chooseSeatMode,
}: TicketProps) => {
  const {inBasket} = place
  const area = isPlaceArea(place)
  const {darkColor} = useColors()
  const {isMobile} = useEnvironment()
  const wholeElementReservation = place.reservationOptions?.reserveWholeElement

  const handleRemove: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    onRemoveClick?.(place)
    handleRemovePopover()
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement | HTMLLIElement> = event => {
    event.stopPropagation()

    onClick?.(place)
  }

  const handleShowPopover = () => setForcedPopover?.(place.id)
  const handleRemovePopover = () => setForcedPopover?.(null)

  return (
    <li
      className={classNames(classes.ticket, {
        [classes.selectedWired]: showAccessibilityMode && !basketView && inBasket && darkColor,
        [classes.selectedNotWired]: showAccessibilityMode && !basketView && inBasket && !darkColor,
        [classes.ticketHover]: !inDescription && !isMobile,
        [classes.ticketMultiBorder]: wholeElementReservation,
        [classes.mobile]: isMobile,
      })}
      onClick={basketView || (area && !chooseSeatMode) || inDescription ? undefined : handleClick}
      onMouseEnter={handleShowPopover}
      onMouseLeave={handleRemovePopover}
      onFocus={handleShowPopover}
      onBlur={handleRemovePopover}
      data-hook={`ticket-${place.id}`}
    >
      {wholeElementReservation ? (
        <div className={classNames(classes.multiBorder, {[classes.mobile]: isMobile})} />
      ) : null}
      <div className={classes.color} style={{backgroundColor: place.color}} />
      <TicketInfo
        inDescription={inDescription}
        basketView={basketView}
        place={place}
        onRemoveClick={onRemoveClick ? handleRemove : undefined}
        showAccessibilityMode={showAccessibilityMode}
      />
      <TicketAction
        place={place}
        inDescription={inDescription}
        onClick={event => (showAccessibilityMode ? handleClick(event) : onTicketMoreInfoClick(place))}
        basketView={basketView}
        showAccessibilityMode={showAccessibilityMode}
      />
      {basketView || inDescription || showAccessibilityMode ? (
        <WholeElementReservation
          place={place}
          basketView={basketView}
          inDescription={inDescription}
          showAccessibilityMode={showAccessibilityMode}
        />
      ) : null}
    </li>
  )
}
