import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import s from '../ticket-details.scss'
import {Checkbox} from '../../../../form/commons/checkbox'
import {Form} from '../../../../form/container'
import {DISABLED_MANDATORY_FIELDS} from '../../../../form/constants'
import {TicketDetailsFormProps} from './interfaces'

export const TicketDetailsForm = ({
  expanded,
  ticketIndex,
  useBuyerDetails,
  toggleUseBuyerDetails,
  formId,
  details,
  submitTicketDetails,
  setTicketDetails,
  selectedTicketsQuantity,
}: TicketDetailsFormProps) => {
  const {t} = useTranslation()

  if (!expanded) {
    return null
  }

  return (
    <div>
      <div className={s.divider} />
      {!ticketIndex && (
        <div className={s.checkbox}>
          <Checkbox
            value={t('mobile.checkout.ticket.useBuyerDetails') as string}
            checked={useBuyerDetails}
            onChange={toggleUseBuyerDetails}
            dataHook={DH.SAME_AS_BUYER_CHECKBOX}
          />
        </div>
      )}
      <div className={s.form}>
        <Form
          form={formId}
          initialValues={details}
          disabledFields={!ticketIndex && useBuyerDetails ? DISABLED_MANDATORY_FIELDS : emptyArr}
          onFormSubmit={submitTicketDetails}
          t={t}
          setTicketDetails={setTicketDetails}
          isTicketForm
          isLastTicket={ticketIndex === selectedTicketsQuantity - 1}
        />
      </div>
    </div>
  )
}

const emptyArr = []
