import React, {useCallback} from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Counter} from 'wix-ui-tpa'
import {checkDonationError} from '../../../../../services/donations'
import {SEATING_ERROR} from '../../../../../actions/seating'
import {getPlaceInfoText} from '../../../../../selectors/seating'
import {classes} from './ticket-area-counter.st.css'
import {TicketAreaCounterProps} from './interfaces'

export const TicketAreaCounter = ({
  place,
  value,
  setValue,
  setPlaceDonationError,
  setSeatingError,
  orderRemainder,
  showAccessibilityMode,
  chooseSeatMode,
}: TicketAreaCounterProps) => {
  const {t} = useTranslation()
  const {capacity, ticket, donation} = place

  const min = showAccessibilityMode ? 0 : 1
  const max = chooseSeatMode ? 1 : capacity

  const placeInfoText = getPlaceInfoText(place, t)

  const handleCounterChange = useCallback(
    (rawValue: string) => {
      let newValue = Number(rawValue)

      if (rawValue === '' || !Number.isInteger(newValue)) {
        return
      }

      if (newValue < min) {
        newValue = min
      } else if (newValue > max) {
        newValue = max
      }

      if (newValue > value) {
        const error = checkDonationError(ticket, donation)
        if (error) {
          setPlaceDonationError({placeId: place.id, error})
          return
        }
      }

      if (newValue > orderRemainder && !chooseSeatMode) {
        setSeatingError(SEATING_ERROR.TICKET_LIMIT_REACHED)
        return
      }

      setValue(newValue)
    },
    [
      max,
      min,
      value,
      orderRemainder,
      chooseSeatMode,
      setSeatingError,
      setPlaceDonationError,
      showAccessibilityMode,
      place.id,
      ticket,
      donation,
    ],
  )

  return (
    <Counter
      className={classes.counter}
      incrementAriaLabel={`${t('seatings_area_counter_increment')} ${placeInfoText}`}
      decrementAriaLabel={`${t('seatings_area_counter_decrement')} ${placeInfoText}`}
      inputAriaLabel={t('seatings_area_counter_input')}
      errorTooltipMaxWidth={300}
      value={value}
      min={min}
      max={max}
      onChange={handleCounterChange}
      aria-pressed={place.selected}
    />
  )
}
