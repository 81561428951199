import {connect} from '../../../../runtime-context/context'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {getBuyerDetails, getTicketDetails, getUseBuyerDetails} from '../../../../../selectors/checkout'
import {getSelectedTicketsQuantity} from '../../../../../selectors/selected-tickets'
import {TicketDetailsFormOwnProps, TicketDetailsFormRuntimeProps} from './interfaces'
import {TicketDetailsForm as Presentation} from './ticket-details-form'

const mapRuntime = (
  {state, actions: {setTicketDetails}}: DetailsPageAppProps,
  ownProps: TicketDetailsFormOwnProps,
): TicketDetailsFormRuntimeProps => ({
  selectedTicketsQuantity: getSelectedTicketsQuantity(state),
  details: getTicketDetails(state, ownProps.ticketIndex),
  buyerDetails: getBuyerDetails(state),
  useBuyerDetails: getUseBuyerDetails(state),
  setTicketDetails,
})

export const TicketDetailsForm = connect<TicketDetailsFormOwnProps, TicketDetailsFormRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
