import React, {useEffect} from 'react'
import classNames from 'classnames'
import {SectionNotification} from 'wix-ui-tpa'
import {focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../classnames.scss'
import {FormStep} from '../../constants'
import {FormSubmitButton} from '../form-submit-button'
import s from './policies.scss'
import {PolicyCheckbox} from './policy-checkbox'
import {PoliciesProps} from './index'

export const Policies = ({
  t,
  toggleAgreePolicies,
  agreedWithPolicies,
  onSubmit,
  openPolicyModal,
  messages,
  rsvp,
  editing,
  policies,
  showAlert,
  paidTicketSelected,
}: PoliciesProps) => {
  useEffect(() => {
    focusElement({selector: hookToAttributeSelector(DH.CHECKOUT_STEP(FormStep.Policies))})
  }, [])
  const submit = () => onSubmit()

  const onPolicyClick = (event: React.MouseEvent<HTMLAnchorElement>, id: string, returnFocusElement) => {
    event.stopPropagation()
    openPolicyModal({policyId: id, returnFocusElement})
  }
  let buttonText = rsvp ? messages.rsvp.submitActionLabel : messages.checkout.submitActionLabel

  if (paidTicketSelected) {
    buttonText = t('policies_continueToPayment')
  }

  return editing ? (
    <div className={s.container}>
      <div className={classNames(c.evTextFont, c.evTextColor, s.description)}>{t('policies_description')}</div>
      <div className={classNames(s.checkboxContainer, {[s.alert]: showAlert})}>
        <PolicyCheckbox
          label={t('policies_agreement')}
          value="policies"
          checked={agreedWithPolicies}
          onChange={() => toggleAgreePolicies()}
          policies={policies}
          onPolicyClick={onPolicyClick}
        />
      </div>
      {showAlert && (
        <div className={s.sectionNotificationContainer}>
          <SectionNotification type="alert">{t('policies_updatedAlert')}</SectionNotification>
        </div>
      )}
      <FormSubmitButton
        dataHook={DH.FORM_BUTTON}
        text={buttonText}
        disabled={!agreedWithPolicies}
        onClick={submit}
        t={t}
      />
    </div>
  ) : null
}
